.button {
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 30px;

  border-color: transparent transparent transparent #38a169;
  transition: 100ms all ease;
  cursor: pointer;

  border-style: solid;
  border-width: 15px 0 15px 24px;
}

.button.paused {
  border-style: double;
  border-width: 0 0 0 24px;
}

.button:hover {
  filter: brightness(130%);
}

.button:focus {
  outline: 0;
}
